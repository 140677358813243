import SmFacebook from '../components/socialMediaIcons/svg/facebook.svg?react';
import SmInstagram from '../components/socialMediaIcons/svg/instagram.svg?react';
import SmYoutube from '../components/socialMediaIcons/svg/youtube.svg?react';
import SmTiktok from '../components/socialMediaIcons/svg/tiktok.svg?react';

// NOTE: These are loaded in the footer and need to be in the main bundle

export const eagerIcons = {
  facebook: SmFacebook,
  instagram: SmInstagram,
  youtube: SmYoutube,
  tiktok: SmTiktok,
};

import { useEffect } from 'react';

const useDarkMode = () => {
  useEffect(() => {
    const applyTheme = e => {
      if (e.matches) {
        document.documentElement.classList.add('theme-dark');
      } else {
        document.documentElement.classList.remove('theme-dark');
      }
    };

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', applyTheme);

    // Initial check
    if (mediaQuery.matches) {
      document.documentElement.classList.add('theme-dark');
    } else {
      document.documentElement.classList.remove('theme-dark');
    }

    // Cleanup listener on unmount
    return () => {
      mediaQuery.removeEventListener('change', applyTheme);
    };
  }, []);
};

export default useDarkMode;

import { createContext, useContext, useMemo } from 'react';
import { getLessonStructuralInfo } from '../utils/lessonUtils';
import { getPlacementDashboardUrl } from '../utils/url/urlFactory';
import { toLangCode } from '../utils/mappers/langMapper';

const contextFactory = structuralData => {
  if (!structuralData) return {};

  const {
    id,
    name,
    title,
    language,
    namedUrl,
    __typename,
    contentLinks,
    dkLearningLevel,
    isPlacementTestCourse,
    isFinalTest,
    lessonId,
    lessonName,
    lessonPath,
    lessonExerciseOverviewItems,
  } = structuralData;
  const isFromPlacementCourse = isPlacementTestCourse;

  const contentURL = isFromPlacementCourse
    ? getPlacementDashboardUrl(toLangCode(language))
    : namedUrl;

  const { lessonGroupNumber, lessonNumberInLessonGroup, lessonNumber } = getLessonStructuralInfo({
    contentLinks,
    lessonId,
  });

  return {
    lessonId,
    lessonParentId: id,
    lessonParentName: __typename === 'Course' ? title : name,
    lessonParentType: __typename,
    language,
    dkLearningLevel,
    contentURL,
    isFromPlacementCourse,
    isFinalTest,
    lessonGroupNumber,
    lessonNumberInLessonGroup,
    lessonNumber,
    lessonName,
    lessonPath,
    lessonExerciseOverviewItems,
  };
};

export const StructuralLessonContext = createContext(contextFactory());

export const useStructuralLessonData = () => useContext(StructuralLessonContext);

export const StructuralLessonContextProvider = ({ structuralData, children }) => {
  const value = useMemo(() => contextFactory(structuralData), [structuralData]);

  return (
    <StructuralLessonContext.Provider value={value}>{children}</StructuralLessonContext.Provider>
  );
};

import { styled } from '@linaria/react';
import { colors, resolutions, columnSize, fontSize, mediaMin } from '../../utils/css';
import DwLogo from '../../assets/svg/dw-logo.svg?react';
import DwLogoWhite from '../../assets/svg/dw-logo-white.svg?react';
import { I18nText } from '../I18n/I18nText.jsx';
import { TeaserHeadline } from '../Headline/Headline.jsx';
import { LANG_CODES } from '../../utils/mappers/langMapper';
import { StyledChineseVariantSwitch } from './ChineseVariantSwitch.jsx';
import { StyledBurgerButton as BurgerButton } from './BurgerButton/BurgerButton.jsx';
import { useLanguage } from '../../context/LanguageContext.jsx';
import { getDashboardUrl } from '../../utils/url/urlFactory';
import useDarkModeStatus from '../../hooks/useDarkModeStatus';

const Banner = ({ className }) => {
  const { langCode } = useLanguage();
  const homeURL = getDashboardUrl(langCode);
  const headerTitleKey = 'header.bar';
  const isDarkMode = useDarkModeStatus();

  return (
    <div className={className}>
      <div>
        <a href={homeURL} aria-label="Logo">
          {isDarkMode ? <DwLogoWhite data-testid="dwLogo" /> : <DwLogo data-testid="dwLogo" />}
        </a>
        <I18nText isA={TeaserHeadline} as="div" translation={headerTitleKey} />
        <div>
          {langCode === LANG_CODES.CHINESE && <StyledChineseVariantSwitch />}
          <BurgerButton />
        </div>
      </div>
    </div>
  );
};

export const StyledBanner = styled(Banner)`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1050;

  background-color: ${colors.LG_WHITE};
  .theme-dark & {
    background-color: ${colors.DARK_BLUE_GREY_01};
  }
  box-shadow:
    inset 0 1px 0 ${colors.LG_TRANSPARENT_WHITE_15},
    0 1px 5px ${colors.LG_TRANSPARENT_BLACK_075};

  > div {
    max-width: ${resolutions.min.xl}px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    padding: 0 ${columnSize.c1};
    height: 100%;

    > a {
      grid-column: 1;
      justify-self: start;
      display: flex;
      align-items: center;

      > svg {
        height: 2rem;
        width: 3.5rem;
      }
    }

    > ${TeaserHeadline} {
      grid-column: 2;
      ${fontSize.plus2}

      ${mediaMin.md} {
        ${fontSize.plus3}
      }
    }

    > div:nth-child(3) {
      grid-column: 3;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
`;

import { invert } from '../commons';

export const LANG_CODES = Object.freeze({
  NEUTRAL: '',
  GERMAN: 'de',
  ENGLISH: 'en',
  RUSSIAN: 'ru',
  CHINESE: 'zh',
  PORTUGUESE_BRAZIL: 'pt-br',
  // ALBANIAN: 'sq',
  // AMHARIC: 'am',
  ARABIC: 'ar',
  // BENGALI: 'bn',
  BOSNIAN: 'bs',
  // BULGARIAN: 'bg',
  // DARI: 'fa-af',
  FRENCH: 'fr',
  GREEK: 'el',
  // HAUSA: 'ha',
  HINDI: 'hi',
  // INDONESIAN: 'id',
  // KISWAHILI: 'sw',
  // CROATIAN: 'hr',
  // MACEDONIAN: 'mk',
  // PASHTO: 'ps',
  PERSIAN: 'fa-ir',
  POLISH: 'pl',
  // PORTUGUESE_AFRICA: 'pt-002',
  ROMANIAN: 'ro',
  // SERBIAN: 'sr',
  SPANISH: 'es',
  TURKISH: 'tr',
  UKRANIAN: 'uk',
  // URDU: 'ur',
  HUNGARIAN: 'hu',
});

export const langCodeList = Object.values(LANG_CODES).filter(Boolean);

const codeToLang = invert(LANG_CODES);

export const toLangCode = lang => lang && LANG_CODES[lang.toUpperCase()];
export const toLang = code => code && codeToLang[code.toLowerCase()];
export const getAllLangCodes = () => langCodeList;
export const langCodeExists = langCode => langCodeList.includes(langCode);

export const langDefs = Object.freeze({
  [LANG_CODES.GERMAN]: {
    gtmLanguageCode: 1,
  },
  [LANG_CODES.ENGLISH]: {
    gtmLanguageCode: 2,
  },
  [LANG_CODES.RUSSIAN]: {
    gtmLanguageCode: 3,
  },
  [LANG_CODES.CHINESE]: {
    gtmLanguageCode: 4,
  },
  [LANG_CODES.PORTUGUESE_BRAZIL]: {
    gtmLanguageCode: 5,
  },
  /* [LANG_CODES.ALBANIAN]: {
    gtmLanguageCode: 5,
  },
  [LANG_CODES.AMHARIC]: {
    gtmLanguageCode: 7,
  }, */
  [LANG_CODES.ARABIC]: {
    gtmLanguageCode: 8,
  },
  /* [LANG_CODES.BENGALI]: {
    gtmLanguageCode: 9,
  }, */
  [LANG_CODES.BOSNIAN]: {
    gtmLanguageCode: 10,
  },
  /* [LANG_CODES.BULGARIAN]: {
    gtmLanguageCode: 11,
  },
  [LANG_CODES.DARI]: {
    gtmLanguageCode: 12,
  }, */
  [LANG_CODES.FRENCH]: {
    gtmLanguageCode: 13,
  },
  [LANG_CODES.GREEK]: {
    gtmLanguageCode: 14,
  },
  /* [LANG_CODES.HAUSA]: {
    gtmLanguageCode: 15,
  }, */
  [LANG_CODES.HINDI]: {
    gtmLanguageCode: 16,
  },
  /* [LANG_CODES.INDONESIAN]: {
    gtmLanguageCode: 17,
  },
  [LANG_CODES.KISWAHILI]: {
    gtmLanguageCode: 18,
  },
  [LANG_CODES.CROATIAN]: {
    gtmLanguageCode: 19,
  },
  [LANG_CODES.MACEDONIAN]: {
    gtmLanguageCode: 20,
  },
  [LANG_CODES.PASHTO]: {
    gtmLanguageCode: 21,
  }, */
  [LANG_CODES.PERSIAN]: {
    gtmLanguageCode: 22,
  },
  [LANG_CODES.POLISH]: {
    gtmLanguageCode: 23,
  },
  /* [LANG_CODES.PORTUGUESE_AFRICA]: {
    gtmLanguageCode: 24,
  }, */
  [LANG_CODES.ROMANIAN]: {
    gtmLanguageCode: 25,
  },
  /* [LANG_CODES.SERBIAN]: {
    gtmLanguageCode: 27,
  }, */
  [LANG_CODES.SPANISH]: {
    gtmLanguageCode: 28,
  },
  [LANG_CODES.TURKISH]: {
    gtmLanguageCode: 29,
  },
  [LANG_CODES.UKRANIAN]: {
    gtmLanguageCode: 30,
  },
  /* [LANG_CODES.URDU]: {
    gtmLanguageCode: 31,
  }, */
  [LANG_CODES.HUNGARIAN]: {
    gtmLanguageCode: 32,
  },
  [LANG_CODES.NEUTRAL]: {
    gtmLanguageCode: null,
  },
});

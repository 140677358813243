import { createContext, useContext } from 'react';
import { isRtlByLangCode } from '../utils/mappers/rtlLanguages';
import { toLang } from '../utils/mappers/langMapper';

export const LanguageContext = createContext({ langCode: 'en', isRtl: false, lang: 'ENGLISH' });

export const LanguageProvider = ({ langCode, children }) => (
  <LanguageContext.Provider
    value={{ langCode, isRtl: isRtlByLangCode(langCode), lang: toLang(langCode) }}
  >
    {children}
  </LanguageContext.Provider>
);

export const useLanguage = () => useContext(LanguageContext);

import { styled } from '@linaria/react';
import gql from 'graphql-tag';
import { Link } from '../Link/Link.jsx';
import { useTranslation } from '../../hooks/useTranslation';
import { toLangCode } from '../../utils/mappers/langMapper';
import { useLanguage } from '../../context/LanguageContext.jsx';
import { FooterLinkInteraction } from '../Interaction/Interaction.jsx';
import { DateTimeInfo } from '../Info/Info.jsx';
import { memoizeConst } from '../../utils/memoize';

const fragmentName = 'FooterLink';
export const footerLinkFragment = {
  name: fragmentName,
  fragment: memoizeConst(
    () => gql`fragment ${fragmentName} on FooterLinkedContent {
      text
      url
      targetLanguage
      isExternal
    }
  `,
  ),
};

const InternalLink = Link;

// @used in ROAD
export const ExternalLink = ({ children, ...restProps }) => (
  <Link native target="_blank" rel="noopener noreferrer nofollow" {...restProps}>
    {children}
  </Link>
);

const useFooterLinkTitle = ({ link, targetLangIsDifferent, languageHintTranslation }) => {
  const externalLinkPrefix = useTranslation('common.link.external_prefix');

  if (link.isExternal) {
    return `${externalLinkPrefix} — ${link.text}${
      targetLangIsDifferent ? ` — ${languageHintTranslation}` : ''
    }`;
  }

  return targetLangIsDifferent ? `${link.text} — ${languageHintTranslation}` : undefined;
};

// @used in ROAD
const FooterLink = ({ className, link }) => {
  const { langCode } = useLanguage();
  const targetLangCode = toLangCode(link.targetLanguage) || langCode;
  const targetLangIsDifferent = targetLangCode !== langCode;

  const hrefLang = targetLangIsDifferent ? targetLangCode : undefined;
  const languageHintTranslation = useTranslation(`footer.language_hint.${targetLangCode}.label`);
  const title = useFooterLinkTitle({ link, targetLangIsDifferent, languageHintTranslation });

  return (
    <li className={className}>
      <FooterLinkInteraction
        as={link.isExternal ? ExternalLink : InternalLink}
        to={link.url}
        title={title}
        hrefLang={hrefLang}
      >
        {link.text}
      </FooterLinkInteraction>
      {targetLangIsDifferent && <DateTimeInfo>{languageHintTranslation}</DateTimeInfo>}
    </li>
  );
};

export const StyledFooterLink = styled(FooterLink)`
  margin: 10px 0;

  > span {
    &::before {
      content: ' (';
    }
    &::after {
      content: ')';
    }
  }
`;

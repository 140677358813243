import { calculatePercentage } from '../../utils/commons';
import {
  findExistingCourseById,
  findExistingLessonById,
  findInquiryProgress,
} from './learnProgress';

export const findLessonLearnProgressSelector = lessonId => state => {
  const { lessons } = state.learnProgress;
  return findExistingLessonById({ lessons, lessonId });
};

const emptyArray = [];
export const findInquiryLearnProgressSelector = (lessonId, exerciseId) => state => {
  if (!lessonId || !exerciseId) {
    return emptyArray;
  }
  const { lessons } = state.learnProgress;
  return findInquiryProgress({ lessons, lessonId, exerciseId });
};

export const findCourseLearnProgressSelector = courseId => state => {
  const { courses } = state.learnProgress;
  return findExistingCourseById({ courses, courseId });
};

export const isLessonProgressSyncRequiredSelector = state => state.learnProgress.isSyncRequired;

export const resultInPercent = lessonId => state => {
  const { maxPointsForAllExercises = 0, resultPoints = 0 } =
    findLessonLearnProgressSelector(lessonId)(state) || {};
  return calculatePercentage(resultPoints, maxPointsForAllExercises);
};

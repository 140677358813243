import { styled } from '@linaria/react';
import BurgerButtonIcon from '../svg/burger.svg?react';
import BurgerButtonIconWhite from '../svg/burger-white.svg?react';
import useDarkModeStatus from '../../hooks/useDarkModeStatus';

const BurgerButton = () => {
  const isDarkMode = useDarkModeStatus();

  return isDarkMode ? <BurgerButtonIconWhite /> : <BurgerButtonIcon />;
};

export const StyledBurgerButtonIcon = styled(BurgerButton)``;

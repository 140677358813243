import { Navigate, Outlet, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { lazy } from 'react';
import { redirect } from 'react-router';
import { MetadataAndGtm, PageContainer } from './components/Page/PageContainer.jsx';
import { LanguageProvider } from './context/LanguageContext.jsx';
import { langCodeList, toLang } from './utils/mappers/langMapper';
import { getFeedbackUrl } from './utils/url/urlFactory';
import { FEEDBACK_TYPES } from './constants/feedback';
import { PAGE_TYPES } from './constants/pageTypes';
import { RestrictedRoute } from './components/RestrictedRoute/RestrictedRoute.jsx';
import { GrammarRedirect } from './components/Redirect/GrammarRedirect.jsx';
import { LoadingMessage } from './components/commons/LoadingMessage.jsx';

const LessonPage = lazy(() => import('./pages/LessonPage.jsx'));
const CoursePage = lazy(() => import('./pages/CoursePage.jsx'));
const FinalTestResultPage = lazy(() => import('./pages/FinalTestResultPage.jsx'));
const PlacementTestResultPage = lazy(() => import('./pages/PlacementTestResultPage.jsx'));
const NavigationPage = lazy(() => import('./pages/NavigationPage.jsx'));
const ContentDetailPage = lazy(() => import('./pages/ContentDetailPage.jsx'));
const VendorConsentPage = lazy(() => import('./pages/VendorConsentPage.jsx'));
const Default404Page = lazy(() => import('./pages/Default404Page.jsx'));

/** @typedef {import("react-router").RouteObject} RouteObject */
/** @typedef {import('@apollo/client').ApolloClient} ApolloClient */

/** @type {import("react-router").LazyRouteFunction<RouteObject>} */
const vocabularyTrainerLazy = () =>
  import('./components/VocabularyTrainer/VocabularyTrainer.jsx').then(({ Component }) => ({
    element: (
      <RestrictedRoute>
        <MetadataAndGtm pageType={PAGE_TYPES.VOCABULARY_TRAINER} addDescription={false}>
          <Component />
        </MetadataAndGtm>
      </RestrictedRoute>
    ),
  }));

/** @type {RouteObject[]} */
const userRoutes = [
  {
    path: 'register',
    children: [
      {
        index: true,
        lazy: () =>
          import('./components/user/Registration.jsx').then(({ Component }) => ({
            element: (
              <MetadataAndGtm pageType={PAGE_TYPES.REGISTER_USER} useDescriptionTranslation>
                <Component />
              </MetadataAndGtm>
            ),
          })),
      },
      {
        path: 'confirm',
        lazy: () =>
          import('./components/user/ConfirmUserRegistrationContainer.jsx').then(
            ({ Component }) => ({
              element: (
                <>
                  <Helmet>
                    <meta name="robots" content="none" />
                  </Helmet>
                  <Component />
                </>
              ),
            }),
          ),
      },
    ],
  },
  {
    path: 'password',
    children: [
      {
        path: 'set',
        lazy: () =>
          import('./components/user/SetNewPassword.jsx').then(({ Component }) => ({
            element: (
              <MetadataAndGtm
                pageType={PAGE_TYPES.PASSWORD_SET}
                addDescription={false}
                noSearchEngine
              >
                <Component />
              </MetadataAndGtm>
            ),
          })),
      },
      {
        path: 'change',
        lazy: () =>
          import('./components/user/PasswordChange.jsx').then(({ Component }) => ({
            element: (
              <RestrictedRoute>
                <MetadataAndGtm
                  pageType={PAGE_TYPES.PASSWORD_CHANGE}
                  addDescription={false}
                  noSearchEngine
                >
                  <Component />
                </MetadataAndGtm>
              </RestrictedRoute>
            ),
          })),
      },
      {
        path: 'reset',
        lazy: () =>
          import('./components/user/PasswordReset.jsx').then(({ Component }) => ({
            element: (
              <MetadataAndGtm pageType={PAGE_TYPES.PASSWORD_RESET} addDescription={false}>
                <Component />
              </MetadataAndGtm>
            ),
          })),
      },
    ],
  },
  {
    path: 'email/change',
    lazy: () =>
      import('./components/user/ConfirmEmailChangeContainer.jsx').then(({ Component }) => ({
        element: (
          <>
            <Helmet>
              <meta name="robots" content="none" />
            </Helmet>
            <Component />
          </>
        ),
      })),
  },
  {
    path: 'profile',
    lazy: () =>
      import('./components/user/StyledUserProfile.jsx').then(({ Component }) => ({
        element: (
          <RestrictedRoute>
            <MetadataAndGtm
              pageType={PAGE_TYPES.USER_PROFILE}
              addDescription={false}
              noSearchEngine
            >
              <Component />
            </MetadataAndGtm>
          </RestrictedRoute>
        ),
      })),
  },
  { path: 'vocabularyTrainer', lazy: vocabularyTrainerLazy },
  { path: 'vocabularyTrainerStart', lazy: vocabularyTrainerLazy },
  {
    path: 'login',
    lazy: () =>
      import('./components/user/Login.jsx').then(({ Component }) => ({
        element: (
          <MetadataAndGtm pageType={PAGE_TYPES.LOGIN} useDescriptionTranslation noSearchEngine>
            <Component />
          </MetadataAndGtm>
        ),
      })),
  },
  {
    path: 'feedback/status/:feedbackType',
    lazy: () =>
      import('./components/StatusFeedback/StatusFeedback.jsx').then(({ Component }) => ({
        element: (
          <MetadataAndGtm
            pageType={PAGE_TYPES.FEEDBACK_STATUS}
            addDescription={false}
            noSearchEngine
          >
            <Component />
          </MetadataAndGtm>
        ),
      })),
  },
];

const ContentPages = () => {
  const { typeAndId } = useParams();

  if (typeAndId?.startsWith('l-')) {
    return <LessonPage />;
  }

  if (typeAndId?.startsWith('c-')) {
    return <CoursePage />;
  }

  if (typeAndId?.startsWith('gr-')) {
    return <GrammarRedirect />;
  }

  if (typeAndId?.startsWith('placement-')) {
    return <PlacementTestResultPage />;
  }

  if (typeAndId?.startsWith('final-')) {
    return <FinalTestResultPage />;
  }

  if (typeAndId?.startsWith('s-')) {
    return <NavigationPage />;
  }

  if (typeAndId?.startsWith('a-')) {
    return <ContentDetailPage />;
  }

  if (typeAndId?.startsWith('privacy-settings-')) {
    return <VendorConsentPage />;
  }

  return <Default404Page />;
};

const loadingFallback = (
  <PageContainer>
    <LoadingMessage />
  </PageContainer>
);

/**
 * @param {import('@apollo/client').ApolloClient} client
 * @param {string} lang
 * @returns {RouteObject[]}
 */
const pages = (client, lang) => [
  {
    index: true,
    loader: () =>
      import('./utils/mappers/browserLanguageMapper')
        .then(({ queryLandingPage }) => queryLandingPage(client, lang))
        .then(url => redirect(url, 307)),
    hydrateFallbackElement: loadingFallback,
    element: null,
  },
  {
    path: 'overview',
    loader: () =>
      import('./utils/mappers/browserLanguageMapper')
        .then(({ queryLandingPage }) => queryLandingPage(client, lang))
        .then(url => redirect(url, 307)),
    hydrateFallbackElement: loadingFallback,
    element: null,
  },
  {
    path: ':title?/:typeAndId',
    children: [{ index: true, path: '*', element: <ContentPages /> }],
  },
  { path: 'grammar', lazy: () => import('./pages/GrammarOverviewPage.jsx') },
  { path: 'help', lazy: () => import('./pages/HelpPage.jsx') },
  { path: 'vocabulary', lazy: () => import('./pages/VocabularyPage.jsx') },
  { path: 'user', children: userRoutes },
  { path: 'placementDashboard', lazy: () => import('./pages/PlacementTestPage.jsx') },
  { path: '*', lazy: () => import('./pages/Default404Page.jsx') },
];

/**
 * @param {import('@apollo/client').ApolloClient} client
 * @returns {RouteObject[]}
 */
export const routes = client => [
  {
    // Should only run in dev mode
    path: '/',
    loader: () =>
      import('./utils/mappers/browserLanguageMapper')
        .then(({ getLandingPage }) => getLandingPage(client, navigator.languages))
        .then(url => redirect(url, 307)),
    hydrateFallbackElement: loadingFallback,
    element: null,
  },
  ...langCodeList.map(
    langCode =>
      /** @type {RouteObject} */ ({
        path: langCode,
        element: (
          <LanguageProvider langCode={langCode}>
            <PageContainer>
              <Outlet />
            </PageContainer>
          </LanguageProvider>
        ),
        errorElement: <Navigate replace to={getFeedbackUrl(FEEDBACK_TYPES.OTHER, langCode)} />,
        hydrateFallbackElement: loadingFallback,
        children: pages(client, toLang(langCode)),
      }),
  ),
  {
    path: '*',
    hydrateFallbackElement: loadingFallback,
    lazy: () =>
      import('./pages/Default404Page.jsx').then(({ Component }) => ({
        element: (
          <PageContainer>
            <Component />
          </PageContainer>
        ),
      })),
  },
];

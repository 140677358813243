import { useState, useEffect } from 'react';

const useDarkModeStatus = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const handleChange = e => {
      setIsDarkMode(e.matches);
    };

    // Set initial state
    setIsDarkMode(mediaQuery.matches);

    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  return isDarkMode;
};

export default useDarkModeStatus;

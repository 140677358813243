import { styled } from '@linaria/react';
import { colors, fontSize } from '../../utils/css';

export const TeaserText = styled.span`
  ${fontSize.base}
  color: ${colors.DARK_BLUE_GREY_02};
  line-height: 1.4;

  .theme-dark & {
    color: ${colors.BLUE_GREY_01};
  }
`;

export const DetailIntroText = styled.p`
  ${fontSize.plus1}
  color: ${colors.DARK_BLUE_GREY_02};
  line-height: 1.4;
  font-weight: 500;

  &.centered {
    text-align: center;
  }

  .theme-dark & {
    color: ${colors.BLUE_GREY_01};
  }
`;

export const detailRichtextTextStyles = `
  ${fontSize.base}
  color: ${colors.DARK_BLUE_GREY_02};
  line-height: 1.4;
`;

export const DetailRichtextText = styled.p`
  ${detailRichtextTextStyles}
  .theme-dark & {
    color: ${colors.LG_WHITE};
  }
`;

export const CaptionText = styled.span`
  ${fontSize.minus1}
  color: ${colors.DARK_BLUE_GREY_01};
  line-height: 1.4;

  .theme-dark & {
    color: ${colors.LG_WHITE};
  }
`;

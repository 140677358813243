import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getFeedbackUrl } from '../../utils/url/urlFactory';
import { userIsLoggedIn } from '../../state/user/userSelectors';
import { StyledLoadingMessage as LoadingMessage } from '../commons/LoadingMessage.jsx';
import { FEEDBACK_TYPES } from '../../constants/feedback';
import { useLanguage } from '../../context/LanguageContext.jsx';

export const RestrictedRoute = ({ children }) => {
  const isLoggedIn = useSelector(userIsLoggedIn);
  const { langCode } = useLanguage();

  if (isLoggedIn === null) {
    return <LoadingMessage />;
  }

  if (isLoggedIn) {
    return children;
  }

  return <Navigate replace to={getFeedbackUrl(FEEDBACK_TYPES.MISSING_USER_PROFILE, langCode)} />;
};

import { hydrateRoot, createRoot } from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ApolloProvider, ApolloClient } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { cacheOptions } from './utils/apolloClient';
import store, { StoreLoader } from './state/store';
import { makeClientSideApolloLink } from './utils/apolloLink';
import { routes } from './routes.jsx';
import useDarkMode from './hooks/useDarkMode';

const cache = new InMemoryCache(cacheOptions);

const client = new ApolloClient({
  link: makeClientSideApolloLink().link,
  ssrForceFetchDelay: 100,
  cache: cache.restore(window.__APOLLO_STATE__), // eslint-disable-line no-underscore-dangle
});

const container = document.getElementById('root');

const router = createBrowserRouter(routes(client), {
  future: {
    v7_relativeSplatPath: true,
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true,
  },
});

function MainApp() {
  useDarkMode();

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <StoreLoader>
          <HelmetProvider>
            <RouterProvider router={router} future={{ v7_startTransition: true }} />
          </HelmetProvider>
        </StoreLoader>
      </Provider>
    </ApolloProvider>
  );
}

if (import.meta.env.PROD) {
  hydrateRoot(container, <MainApp />);
} else {
  createRoot(container).render(<MainApp />);
}

serviceWorker.unregister();

import { useEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';
import { useGlobalsContext } from '../context/GlobalsContext';
import { usePrev } from './usePrev';

export const useScrollToTop = () => {
  const { window } = useGlobalsContext();
  const location = useLocation();
  const prevLocation = usePrev(location);
  const navType = useNavigationType();

  useEffect(() => {
    if (prevLocation !== undefined && prevLocation !== location && navType !== 'POP') {
      window.scrollTo(0, 0);
    }
  }, [window, location, navType, prevLocation]);
};

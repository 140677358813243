import { styled } from '@linaria/react';
import { colors, mediaMin, resolutions } from '../utils/css';
import { StyledPageLayout as PageLayout } from '../components/Page/PageLayout.jsx';
import { GlobalFonts } from '../components/GlobalFonts/GlobalFonts.jsx';
import { useCheckSessionTimeout } from '../hooks/useCheckSessionTimeout';
import { useSyncLearnProgress } from '../hooks/useSyncLessonProgress';
import { useScrollToTop } from '../hooks/useScrollToTop';
import {
  DisableOutlineGlobalStyle,
  StyledFlyingFocus,
} from '../components/FlyingFocus/FlyingFocus.jsx';
import { useLanguage } from '../context/LanguageContext.jsx';

export const DISABLE_SCROLLING_OVERFLOW_CSS_VARIABLE = '--global-disable-scrolling-overflow';
export const DISABLE_SCROLLING_PADDING_CSS_VARIABLE = '--global-disable-scrolling-padding';
export const OUTER_PADDING_CSS_VARIABLE_NAME = '--global-outer-padding';

export const GlobalStyles = styled.div`
  :global() {
    :root {
      ${DISABLE_SCROLLING_OVERFLOW_CSS_VARIABLE}: initial;
      ${DISABLE_SCROLLING_PADDING_CSS_VARIABLE}: initial;
      ${OUTER_PADDING_CSS_VARIABLE_NAME}: 0px;

      ${mediaMin.xl} {
        ${OUTER_PADDING_CSS_VARIABLE_NAME}: calc((100% - ${resolutions.min.xl}px) / 2);
      }
    }

    * {
      box-sizing: border-box;
      -moz-osx-font-smoothing: grayscale;
    }
    &:after,
    &:before {
      box-sizing: border-box;
    }
    html {
      font-size: min(calc(15px + ((100vw - 375px) / 213)), 20px);
      -webkit-tap-highlight-color: transparent;

      &[lang='ar'] {
        font-feature-settings: 'liga' 0;
      }

      > body {
        direction: ltr;
        text-align: left;
      }

      /* All rtl languages */
      &[lang='ar'],
      &[lang='fa'] {
        > body {
          direction: rtl;
          text-align: right;
        }

        * {
          letter-spacing: 0 !important;
        }
      }
    }
    body {
      background-color: ${colors.LG_WHITE};
      .theme-dark & {
        background-color: ${colors.DARK_BLUE_GREY_01};
      }
      padding-right: var(${DISABLE_SCROLLING_PADDING_CSS_VARIABLE});
      overflow: var(${DISABLE_SCROLLING_OVERFLOW_CSS_VARIABLE});
    }
    .theme-dark body {
      background-color: ${colors.DARK_BLUE_GREY_01};
    }
    svg:not(:root) {
      overflow: hidden;
    }
    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
    }

    button {
      cursor: pointer;
      &:disabled {
        cursor: default;
        pointer-events: none;
      }
    }

    p,
    li {
      margin: 0 0 10px;
    }

    dl,
    ol,
    ul {
      margin-top: 0;
    }

    strong,
    b {
      font-weight: 600;
    }
  }
`;

export const MainPage = ({ children }) => {
  const { langCode } = useLanguage();
  useCheckSessionTimeout(langCode);
  useSyncLearnProgress();
  useScrollToTop();

  return (
    <>
      <GlobalStyles />
      <DisableOutlineGlobalStyle />
      <StyledFlyingFocus />
      <GlobalFonts />
      <PageLayout>{children}</PageLayout>
    </>
  );
};

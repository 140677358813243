import { styled } from '@linaria/react';
import { colors, fontSize } from '../../utils/css';

export const TeaserHeadline = styled.h3`
  ${fontSize.plus1}
  color: ${colors.DW_DARK_BLUE_NEW};
  line-height: 1.1;
  font-weight: 600;
  font-variation-settings: 'opsz' 20;
  hyphens: auto;

  .theme-dark & {
    color: ${colors.LG_WHITE};
  }
`;

export const ContentBlockHeadline = styled.h2`
  ${fontSize.plus1}
  color: ${colors.BLUE_GREY_04};
  line-height: 1.1;
  font-weight: 500;
  font-variation-settings: 'opsz' 20;
  hyphens: auto;

  .theme-dark & {
    color: ${colors.DW_LIGHT_BLUE_NEW};
  }
`;

export const NavigationSectionHeadline = styled.h3`
  ${fontSize.minus1}
  color: ${colors.DARK_BLUE_GREY_02};
  line-height: 1.1;
  letter-spacing: 0.1em;
  font-weight: 400;
  font-variation-settings: 'opsz' 20;
  text-transform: uppercase;
  hyphens: auto;

  .theme-dark & {
    color: ${colors.BLUE_GREY_02};
  }
`;

export const DetailPageHeadline = styled.h1`
  ${fontSize.plus4}
  color: ${colors.DW_DARK_BLUE_NEW};
  line-height: 1.1;
  font-weight: 600;
  font-variation-settings: 'opsz' 20;
  hyphens: auto;

  &.theme-dark,
  .theme-dark & {
    color: ${colors.LG_WHITE};
  }
`;

export const OverviewPageHeadline = styled.h1`
  ${fontSize.plus4}
  color: ${colors.BLUE_GREY_04};
  line-height: 1.1;
  font-weight: 600;
  font-variation-settings: 'opsz' 20;
  hyphens: auto;

  .theme-dark & {
    color: ${colors.BLUE_GREY_03};
  }
`;

// These are applied with descendent selectors
export const richTextHeadlineStyles = `
  ${fontSize.plus2}
  color: ${colors.DARK_BLUE_GREY_02};
  line-height: 1.1;
  font-weight: 600;
  font-variation-settings: 'opsz' 20;
  hyphens: auto;
`;

export const RichTextHeadline = styled.h2`
  ${richTextHeadlineStyles}
  .theme-dark & {
    color: ${colors.LG_WHITE};
  }
`;

export const richTextSubHeadlineStyles = `
  ${fontSize.plus1}
  color: ${colors.DARK_BLUE_GREY_02};
  line-height: 1.1;
  font-weight: 600;
  font-variation-settings: 'opsz' 20;
  hyphens: auto;
`;

export const RichTextSubHeadline = styled.h3`
  ${richTextSubHeadlineStyles}
  .theme-dark & {
    color: ${colors.BLUE_GREY_01};
  }
`;

export const LessonMenuHeadline = styled('h1')`
  ${fontSize.plus1}
  color: ${colors.DW_DARK_BLUE_NEW};
  line-height: 1.1;
  font-weight: 600;
  font-variation-settings: 'opsz' 20;
  hyphens: auto;
`;

import { Navigate, useParams } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext.jsx';

export const GrammarRedirect = () => {
  const { typeAndId } = useParams();
  const contentId = typeAndId?.replace('gr-', '');
  const { langCode } = useLanguage();

  return <Navigate replace to={`/${langCode}/grammar#${contentId}`} />;
};

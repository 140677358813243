import createEngine from 'redux-storage-engine-localstorage';
import { createStore, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';
import { useEffect } from 'react';
import { createMiddleware, loadStorage, reducerFunc } from './redux-storage';
import rootReducer from './rootReducers';
import globals from '../utils/globals';

export const reducer = reducerFunc(rootReducer);
const engine = createEngine('learngerman');
const storageMiddleware = createMiddleware(engine);

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = globals.window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const createStoreWithMiddleware = composeEnhancers(
  applyMiddleware(thunk, storageMiddleware),
)(createStore);

const store = createStoreWithMiddleware(reducer);

export const StoreLoader = ({ children }) => {
  // IMPORTANT: Don't load before first render finishes. You might get hydration mismatches.
  useEffect(() => {
    loadStorage(engine, store);
  });
  return children;
};

export default store;

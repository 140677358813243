import { useQuery } from '@apollo/client';
import { useEffect, useState, useRef, lazy, Suspense } from 'react';
import { styled } from '@linaria/react';
import { cx } from '@linaria/core';
import { StyledLoadingMessage as LoadingMessage } from '../commons/LoadingMessage.jsx';
import { StyledErrorMessage as ErrorMessage } from '../commons/ErrorMessage.jsx';
import { colors } from '../../utils/css';
import { useGlobalsContext } from '../../context/GlobalsContext';

const Default404Page = lazy(() => import('../../pages/Default404Page.jsx'));

const hasData = data =>
  data && Object.values(data).some(item => (Array.isArray(item) ? item.length > 0 : item));

export const WithGraphQLQueryHandling = ({ query, queryVariables, children }) => {
  const queryResult = useQuery(query, {
    variables: queryVariables,
  });

  if (queryResult.loading) {
    return <LoadingMessage />;
  }

  if (queryResult.error) {
    const { message = 'error while fetching data' } = queryResult.error;
    return <ErrorMessage error={message} />;
  }

  if (!hasData(queryResult.data)) {
    return (
      <Suspense fallback={<LoadingMessage />}>
        <Default404Page />
      </Suspense>
    );
  }

  return children(queryResult);
};

/**
 * @param{{
 *   query: Parameters<typeof useQuery>[0],
 *   children: (data: {}) => React.ReactNode,
 * } & Parameters<typeof useQuery>[1]
 */
const SlidingQueryHandling = ({ query, className, children, delayTransition, ...queryOptions }) => {
  const [transitionReady, setTransitionReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const containerRef = useRef();
  const { window } = useGlobalsContext();

  const queryResult = useQuery(query, queryOptions);

  useEffect(() => {
    if (!hasData(queryResult.data) || delayTransition || transitionReady) {
      return;
    }

    // Need to delay here, because RichText replacements might still occur
    setTimeout(() => {
      if (window.matchMedia('(prefers-reduced-motion: no-preference)').matches) {
        const startHeight = `${containerRef.current.offsetHeight}px`;
        containerRef.current.style.height = 'auto';
        const endHeight = `${containerRef.current.offsetHeight}px`;

        containerRef.current
          .animate({ height: [startHeight, endHeight] }, { duration: 700, easing: 'ease' })
          .addEventListener(
            'finish',
            () => {
              containerRef.current.style.height = '';
              setLoading(false);
            },
            { once: true },
          );
      } else {
        setLoading(false);
      }

      setTransitionReady(true);
    });
  }, [queryResult.data, delayTransition, transitionReady, window]);

  if (queryResult.error) {
    const { message = 'error while fetching data' } = queryResult.error;
    return <ErrorMessage error={message} />;
  }

  if (!queryResult.loading && queryResult.called && !hasData(queryResult.data)) {
    return (
      <Suspense fallback={<LoadingMessage />}>
        <Default404Page />
      </Suspense>
    );
  }
  return (
    <div ref={containerRef} className={cx(className, transitionReady && 'open')}>
      {hasData(queryResult.data) && children(queryResult)}
      {loading && <LoadingMessage />}
    </div>
  );
};

export const StyledSlidingQueryHandling = styled(SlidingQueryHandling)`
  position: relative;
  height: 190px;

  > ${LoadingMessage} {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${colors.LG_WHITE};

    height: 100%;
    width: 100%;
    opacity: 1;
    @media (prefers-reduced-motion: no-preference) {
      transition: opacity 0.7s;
    }
  }

  &.open {
    height: auto;
    > ${LoadingMessage} {
      opacity: 0;
    }
  }
`;

import gql from 'graphql-tag';
import { sumScore } from './utils';
import { filterDataFromService } from './serverDataFilterUtils';
import { memoizeWith } from '../../utils/memoize';

/**
 * the following data structure is generated here as lesson progress state for the global state
{
    cosLessonId: 37250531,
    cosCourseId: 36519789,
    doneExerciseCount: 1,
    allExerciseCount: 12,
    maxPointsForAllExercises: 35,
    resultPoints: 1,
    exerciseProgresses: [
    {
        cosExerciseId: 42382962,
        resultPoints: 1,
        maxPoints: 2,
        inquiryProgresses: [
            {
                inquiryId: 42382995,
                score: 1,
                maxScore: 1,
            },
            {
                inquiryId: 42382999,
                score: 0,
                maxScore: 1,
            }
        ]
    }],
}
 */

const fragmentName = ({ type, addLearningMetaInfo = true, handicap }) =>
  `${type}Score${handicap || ''}${addLearningMetaInfo ? 'WithMetaInfo' : ''}`;

export const scoreFragment = {
  name: fragmentName,
  fragment: memoizeWith(
    params => fragmentName(params),
    ({ type, addLearningMetaInfo = true, handicap }) => {
      const targetHandicap = handicap ? `(handicap: ${handicap})` : '';

      return addLearningMetaInfo
        ? gql`
          fragment ${fragmentName({ type, addLearningMetaInfo, handicap })} on ${type} {
            learningMetaInfo${targetHandicap} {
              achievableScore
              exerciseCount
            }
          }
        `
        : gql`
          fragment ${fragmentName({ type, addLearningMetaInfo, handicap })} on ${type} {
            id
          }
        `;
    },
  ),
};

export const createLearnProgressData = ({
  langCode,
  lessonId,
  courseId,
  lessonAchievableScore,
  exerciseId,
  allExerciseCount,
  exerciseProgress,
  exerciseAchievableScore,
}) => {
  return {
    language: langCode,
    cosLessonId: lessonId,
    cosCourseId: courseId,
    maxPointsForAllExercises: lessonAchievableScore,
    cosExerciseId: exerciseId,
    allExerciseCount,
    exerciseProgress,
    exerciseAchievableScore,
  };
};

export const initializeLessonLearnProgressWithDataFromServer = ({
  lessonProgresses = emptyArray,
}) => {
  const filteredLessonProgresses = lessonProgresses.map(lessonProgress =>
    filterDataFromService(lessonProgress),
  );
  return { lessons: filteredLessonProgresses };
};

export const updateLessonProgressFromService = ({ lessons, lessonProgress }) => {
  const filteredLessonProgress = filterDataFromService(lessonProgress);
  const existingLesson = findExistingLesson({ lessons, lessonProgress: filteredLessonProgress });
  if (existingLesson) {
    const otherLessons = lessons.filter(
      lesson => lesson.cosLessonId !== filteredLessonProgress.cosLessonId,
    );
    return { lessons: [...otherLessons, filteredLessonProgress] };
  }
  return {
    lessons: [...lessons, filteredLessonProgress],
  };
};

export const deleteLessonProgressById = ({ lessons, lessonId }) => {
  const lessonProgressToDelete = findExistingLessonById({ lessons, lessonId });
  if (!lessonProgressToDelete) {
    return { lessons };
  }
  const otherLessons = lessons.filter(lesson => lesson.cosLessonId !== lessonId);
  return { lessons: [...otherLessons] };
};

export const deleteLessonProgressesByCourseId = ({ lessons, courseId }) => {
  return { lessons: lessons.filter(lesson => lesson.cosCourseId !== courseId) };
};

export const deleteCourseProgressById = ({ courses, courseId }) => {
  const courseProgressToDelete = findExistingCourseById({ courses, courseId });
  if (!courseProgressToDelete) {
    return { courses };
  }
  const otherCourses = courses.filter(course => course.cosCourseId !== courseId);
  return { courses: [...otherCourses] };
};

export const addCourseProgressData = ({ courses, courseProgress }) => {
  const existingCourseProgress = findExistingCourseById({
    courses,
    courseId: courseProgress.cosCourseId,
  });

  if (existingCourseProgress) {
    const otherCourses = courses.filter(
      course => course.cosCourseId !== courseProgress.cosCourseId,
    );
    return {
      courses: [...otherCourses, courseProgress],
    };
  }

  return { courses: [...courses, courseProgress] };
};

export const findExistingLessonById = ({ lessons, lessonId }) => {
  return lessons.find(lesson => lesson.cosLessonId === lessonId);
};

export const findExistingCourseById = ({ courses, courseId }) => {
  return courses.find(course => course.cosCourseId === courseId);
};

export const findExistingLesson = ({ lessons, lessonProgress }) => {
  return lessons.find(lesson => lesson.cosLessonId === lessonProgress.cosLessonId);
};

const emptyArray = [];
export const findInquiryProgress = ({ lessons, lessonId, exerciseId }) => {
  const lesson = findExistingLessonById({ lessons, lessonId });
  if (!lesson) {
    return emptyArray;
  }
  const { exerciseProgresses } = lesson;

  const currentExerciseProgress = exerciseProgresses.find(
    exerciseProgress => exerciseProgress.cosExerciseId === exerciseId,
  );

  if (!currentExerciseProgress) {
    return emptyArray;
  }
  const { inquiryProgresses = emptyArray } = currentExerciseProgress;
  return inquiryProgresses;
};

export const generateLessonProgress = ({ lessons, lessonProgress }) => {
  const { exerciseProgress } = lessonProgress;
  if (exerciseProgress.inquiryProgresses.length === 0) {
    return { lessons };
  }
  const existingLesson = findExistingLesson({ lessons, lessonProgress });
  if (existingLesson) {
    const otherLessons = lessons.filter(
      lesson => lesson.cosLessonId !== lessonProgress.cosLessonId,
    );
    return {
      lessons: [...otherLessons, updateExistingLessonMetaData(existingLesson, lessonProgress)],
    };
  }
  return {
    lessons: [...lessons, createNewLessonMetaData(lessonProgress)],
  };
};

const updateExerciseMetaDataForExistingLesson = (existingLesson, lessonProgress) => {
  const { exerciseProgresses } = existingLesson;
  const existingExercise = exerciseProgresses.find(
    exercise => exercise.cosExerciseId === lessonProgress.cosExerciseId,
  );
  if (existingExercise) {
    const otherExercises = exerciseProgresses.filter(
      exercise => exercise.cosExerciseId !== lessonProgress.cosExerciseId,
    );
    return [...otherExercises, updateExerciseMetaData(existingExercise, lessonProgress)];
  }
  return [...exerciseProgresses, createNewExerciseMetaData(lessonProgress)];
};

const updateExistingLessonMetaData = (existingLesson, lessonProgress) => {
  const newExerciseProgresses = [
    ...updateExerciseMetaDataForExistingLesson(existingLesson, lessonProgress),
  ];
  const newDoneExerciseCount = newExerciseProgresses.length;

  const updatedLesson = {
    ...existingLesson,
    doneExerciseCount: newDoneExerciseCount,
    exerciseProgresses: newExerciseProgresses,
  };
  return {
    ...updatedLesson,
    resultPoints: sumScore(updatedLesson.exerciseProgresses, 'resultPoints'),
  };
};

const createNewLessonMetaData = lessonProgress => {
  const exerciseProgresses = [createNewExerciseMetaData(lessonProgress)];
  const { cosLessonId, cosCourseId, allExerciseCount, maxPointsForAllExercises, language } =
    lessonProgress;
  const newLesson = {
    language,
    cosLessonId,
    cosCourseId,
    allExerciseCount,
    doneExerciseCount: exerciseProgresses.length,
    maxPointsForAllExercises,
    exerciseProgresses,
  };

  return { ...newLesson, resultPoints: sumScore(newLesson.exerciseProgresses, 'resultPoints') };
};

const createNewExerciseMetaData = lessonProgress => {
  const { cosExerciseId, exerciseProgress, exerciseAchievableScore } = lessonProgress;
  return {
    cosExerciseId,
    maxPoints: exerciseAchievableScore,
    resultPoints: sumScore(exerciseProgress.inquiryProgresses, 'score'),
    inquiryProgresses: exerciseProgress.inquiryProgresses,
  };
};

const updateExerciseMetaData = (existingExercise, lessonProgress) => {
  const { cosExerciseId, exerciseProgress, exerciseAchievableScore } = lessonProgress;

  const cleanedExistingInquiryProgresses = existingExercise.inquiryProgresses.filter(
    inquiryProgress =>
      !exerciseProgress.inquiryProgresses.some(
        newInquiryProgress => newInquiryProgress.inquiryId === inquiryProgress.inquiryId,
      ),
  );

  const inquiryProgresses = [
    ...cleanedExistingInquiryProgresses,
    ...exerciseProgress.inquiryProgresses,
  ];

  return {
    cosExerciseId,
    maxPoints: exerciseAchievableScore,
    resultPoints: sumScore(inquiryProgresses, 'score'),
    inquiryProgresses,
  };
};

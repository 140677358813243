import { css, cx } from '@linaria/core';

import { mediaMax, columnSize } from '../../utils/css';
import { ExternalLink } from './FooterLink.jsx';
import { useTranslation } from '../../hooks/useTranslation';
import { NavigationLinkInteraction } from '../Interaction/Interaction.jsx';
import { eagerIcons } from '../../utils/eagerIcons';

const style = css`
  width: ${columnSize.c1};

  ${mediaMax.md} {
    width: 40px;
  }

  ${NavigationLinkInteraction} {
    display: block;
    width: 40px;
    height: 40px;
  }

  ${NavigationLinkInteraction}:not(:hover):not(:focus) svg {
    fill: currentcolor;
  }
`;

// @used in ROAD
export const SocialMediaAccountLink = ({ socialMediaAccount, ...restProps }) => {
  const { service, url } = socialMediaAccount;
  const styles = cx(style, service);
  const externalLinkPrefix = useTranslation('common.link.external_prefix');
  const SocialMediaIconTag = eagerIcons[service];

  if (!SocialMediaIconTag) {
    return null;
  }

  return (
    <li className={styles}>
      <NavigationLinkInteraction
        as={ExternalLink}
        to={url}
        title={`${externalLinkPrefix} — ${service}`}
        {...restProps}
      >
        <SocialMediaIconTag />
      </NavigationLinkInteraction>
    </li>
  );
};

import gql from 'graphql-tag';
import { createContext, useContext } from 'react';
import { memoizeConst } from '../utils/memoize';

const fragmentName = 'NavigationRootFragment';
export const navigationRootFragment = {
  name: fragmentName,
  fragment: memoizeConst(
    () => gql`
      fragment ${fragmentName} on LgNavigationRoot {
        learningLevelNavigations(lang: $lang) {
          id
          name
          namedUrl
          language
        }
        allCoursesNavigation(lang: $lang) {
          id
          namedUrl
        }
      }
    `,
  ),
};

export const NavigationRootContext = createContext();

export const useNavigationRoot = () => useContext(NavigationRootContext);

import { styled } from '@linaria/react';
import TickImg from '../../../../../assets/svg/tick.svg?react';
import { useI18nContext } from '../../../../../context/I18nContext.jsx';
import { isLanguageSelected } from './langUtils';
import { useNavigationContext } from '../../NavigationContext.jsx';
import { NavigationLinkInteraction } from '../../../../Interaction/Interaction.jsx';
import { getDashboardUrl } from '../../../../../utils/url/urlFactory';
import { getChineseQuery } from '../../../../../constants/LGLanguageCodes';

export const LanguageSelectionButton = ({
  language,
  translationKey: languageTranslationKey,
  langCode: currentLanguage,
}) => {
  const i18n = useI18nContext();
  const { isTraditionalChin } = useNavigationContext();
  const isSelected = isLanguageSelected({
    languageTranslationKey,
    currentLanguage,
    isTraditionalChin,
  });

  return (
    <StyledLanguageSelectionButton>
      <NavigationLinkInteraction
        as="a"
        href={`${getDashboardUrl(language)}${getChineseQuery(languageTranslationKey)}`}
      >
        <span>{i18n.t(`header.menu.language.${languageTranslationKey}`)}</span>
        <span> - </span>
        <span>{i18n.t(`header.menu.language.native.${languageTranslationKey}`)}</span>
        {isSelected && (
          <i className="icon">
            <TickImg />
          </i>
        )}
      </NavigationLinkInteraction>
    </StyledLanguageSelectionButton>
  );
};

const StyledLanguageSelectionButton = styled('li')`
  display: block;
  float: inline-start;
  width: 100%;
  margin: 10px;

  &:first-child {
    margin-top: 20px;
  }

  i {
    margin: 0 10px;
  }
`;

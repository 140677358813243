import { styled } from '@linaria/react';
import { colors, mediaMin } from '../../utils/css';

const PageLayout = ({ className, children }) => (
  <div className={className} aria-label="Page Container">
    {children}
  </div>
);

export const StyledPageLayout = styled(PageLayout)`
  background: ${colors.LG_WHITE};
  .theme-dark & {
    background: ${colors.DARK_BLUE_GREY_01};
  }
  margin: 4rem auto 0;
  overflow-x: hidden !important;
  padding: 0;

  ${mediaMin.xl} {
    min-height: 600px;
  }
`;

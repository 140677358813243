import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { GtmNoScriptFallback } from './GtmNoScriptFallback.jsx';
import { useStaticInfoForGtm } from './hooks/useStaticInfoForGtm';
import { TrackingUtils } from './TrackingUtils';
import { useStructuralLessonData } from '../../context/StructuralLessonDataContext.jsx';
import { useCourseData } from '../../context/CourseDataContext';
import { useExerciseData } from '../../context/ExerciseDataContext';
import { userDataSelector, userIsLoggedIn } from '../../state/user/userSelectors';
import { useLanguage } from '../../context/LanguageContext.jsx';
import { useGlobalsContext } from '../../context/GlobalsContext';

// @used in ROAD
export const GtmDataLayer = ({ content, noContentPageName, lessonItem }) => {
  const { gtmLevel1Id: level1Id, macaParam, pageUrl, gtmEnvironment } = useStaticInfoForGtm();
  const { courseId, dkLearningLevel: dkLearningLevelCourse, courseTitle, type } = useCourseData();
  const {
    lessonGroupNumber,
    lessonNumberInLessonGroup,
    lessonNumber,
    lessonParentId,
    lessonParentType,
    lessonParentName,
    lessonName,
    dkLearningLevel: dkLearningLevelStructuralData,
  } = useStructuralLessonData();
  const { exerciseNumber, exerciseName } = useExerciseData();
  const { handicap } = useSelector(userDataSelector) || {};
  const isLoggedIn = useSelector(userIsLoggedIn);
  const { langCode } = useLanguage();
  const { window } = useGlobalsContext();

  const learningContextId = courseId || lessonParentId;
  const learningContextName = courseTitle || lessonParentName;
  const learningContextType = type || lessonParentType;
  const dkLearningLevel = dkLearningLevelCourse || dkLearningLevelStructuralData;
  const dataLayer = useMemo(
    () => {
      const learningRelatedData = {
        dkLearningLevel,
        learningContextId,
        learningContextType,
        learningContextName,
        lessonName,
        lessonGroupNumber,
        lessonNumberInLessonGroup,
        lessonNumber,
        exerciseNumber,
        exerciseName,
        lessonItem,
      };
      const userRelatedData = { isLoggedIn, handicap };
      return TrackingUtils.generateDataLayer({
        content,
        macaParam,
        level1Id,
        pageUrl,
        learningRelatedData,
        userRelatedData,
        noContentPageName,
        gtmEnvironment,
        langCode,
      });
    }, // isLoggedIn and handicap are changing on first page call and responsible for two
    // onPageChange calls. Those kind of changes are not relevant for our tracking (only other
    // fields), therefore excluded them from deps array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      content,
      dkLearningLevel,
      exerciseName,
      exerciseNumber,
      learningContextId,
      learningContextName,
      learningContextType,
      lessonGroupNumber,
      lessonName,
      lessonNumber,
      lessonNumberInLessonGroup,
      level1Id,
      macaParam,
      noContentPageName,
      pageUrl,
      lessonItem,
      gtmEnvironment,
      langCode,
    ],
  );

  useEffect(() => {
    TrackingUtils.pushToGoogleTagManager({
      window,
      withReset: true,
      datalayerObj: {
        event: 'onPageChange',
        ...dataLayer,
      },
    });
  }, [dataLayer, window]);

  return <GtmNoScriptFallback dataLayer={dataLayer} />;
};
